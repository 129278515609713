import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Input from "../../../components/UI/Input/Input";
import { checkValidity, checkMatchValidity } from "../../../hoc/Util";
import { SysMapper } from "../../../hoc/FormConfig";

import axios from "axios";

// Custom Styling
const customStyle = {
  topBuffer30: {
    marginTop: "30px",
  },

  topBuffer20: {
    marginTop: "20px",
  },

  tabSpan: {
    marginTop: "25px",
    textAlign: "justify",
  },

  errorMessage: {
    marginTop: "10px",
    color: "red",
    fontWeight: "bold",
  },

  successMessage: {
    marginTop: "10px",
    color: "green",
    fontWeight: "bold",
  },
};

class Settings extends Component {
  state = {
    controls: {
      oldPassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Old Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Old Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },

        value: "",
        valid: false,
        touched: false,
      },

      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "New Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "New Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },

        value: "",
        valid: false,
        touched: false,
      },

      confirmPassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Re-type New Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Re-type New Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },

        value: "",
        valid: false,
        touched: false,
      },
    },

    profile: {
      loaded: false,
      params: {
        firstname: {
          label: "First Name:",
          content: "",
        },
        lastname: {
          label: "Last Name:",
          content: "",
        },
        email: {
          label: "Email:",
          content: "",
        },
        affiliation: {
          label: "Affiliation:",
          content: "",
        },
        department: {
          label: "Department / Faculty:",
          content: "",
        },
        type: {
          label: "User Type:",
          content: "",
        },
        dateregistered: {
          label: "Date Registered",
          content: "",
        },
      },
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = null;

    if (controlName === "confirmEmail") {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["email"].value,
        "Emails"
      );
    } else if (controlName === "confirmPassword") {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["password"].value,
        "Passwords"
      );
    } else if (
      controlName === "password" &&
      this.state.controls["confirmPassword"].value.length > 0
    ) {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["confirmPassword"].value,
        "Passwords"
      );
    } else if (
      controlName === "email" &&
      this.state.controls["confirmEmail"].value.length > 0
    ) {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["confirmEmail"].value,
        "Passwords"
      );
    } else {
      validateOutput = checkValidity(
        event.target.value,
        this.state.controls[controlName].validation
      );
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  componentDidMount() {
    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "settings/fetch",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
      })
        .then((received) => {
          if (received.status === 200) {
            this.setState({
              profile: {
                loaded: true,
                params: {
                  firstname: {
                    label: "First Name:",
                    content: received.data.payload.firstname,
                  },
                  lastname: {
                    label: "Last Name:",
                    content: received.data.payload.lastname,
                  },
                  email: {
                    label: "Email:",
                    content: received.data.payload.email,
                  },
                  affiliation: {
                    label: "Affiliation:",
                    content: SysMapper[received.data.payload.affiliation]
                      ? SysMapper[received.data.payload.affiliation]
                      : received.data.payload.affiliation,
                  },
                  department: {
                    label: "Department / Faculty:",
                    content: received.data.payload.department,
                  },
                  type: {
                    label: "User Type:",
                    content: SysMapper[received.data.payload.type],
                  },
                  dateregistered: {
                    label: "Date Registered",
                    content: received.data.payload.dateregistered,
                  },
                },
              },

              internalMsg: {
                triggered: true,
                type: "success",
                content: "",
              },
            });
          } else {
            this.setState({
              internalMsg: { triggered: true, type: "error", content: "" },
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.setState({
              internalMsg: { triggered: true, type: "error", content: "" },
            });
          }
        });
    } else {
      this.setState({
        internalMsg: { triggered: true, type: "error", content: "" },
      });
    }
  }

  submitHandler = (event) => {
    event.preventDefault();

    if (
      this.state.controls.oldPassword.valid &&
      this.state.controls.password.valid &&
      this.state.controls.confirmPassword.valid
    ) {
      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "settings/post",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            oldpass: this.state.controls.oldPassword.value,
            newpass: this.state.controls.password.value,
          },
        })
          .then((received) => {
            let _success = received.data.status;
            let _message = received.data.message;

            if (_success) {
              this.setState({
                internalMsg: {
                  triggered: true,
                  type: "success",
                  content: "Password changed successfully!",
                },
                controls: {
                  ...this.state.controls,
                  oldPassword: {
                    ...this.state.controls.oldPassword,
                    value: "",
                    valid: false,
                    touched: false,
                  },
                  password: {
                    ...this.state.controls.password,
                    value: "",
                    value: "",
                    valid: false,
                    touched: false,
                  },
                  confirmPassword: {
                    ...this.state.controls.confirmPassword,
                    value: "",
                    valid: false,
                    touched: false,
                  },
                },
              });
            } else {
              this.setState({
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: _message,
                },
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.setState({
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "Error code 4221: Contact site adminstrator",
                },
              });
            }
          });
      } else {
        this.setState({
          internalMsg: {
            triggered: true,
            type: "error",
            content: "Invalid or expired session",
          },
        });
      }
    }
  };

  render() {
    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    let profileElement = <p>Retrieving Profile...</p>;
    if (this.state.profile.loaded) {
      profileElement = Object.entries(this.state.profile.params).map(
        ([key, value], idx) => {
          return (
            <div key={idx}>
              <Form.Group as={Row}>
                <Form.Label column>{value.label}</Form.Label>
                <Col>
                  <Form.Control
                    disabled
                    readOnly
                    defaultValue={value.content}
                  />
                </Col>
              </Form.Group>
            </div>
          );
        }
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        elementDecorators={formElement.config.elementDecorators}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    let msg = null;
    if (
      this.state.internalMsg.triggered &&
      this.state.internalMsg.type === "error"
    ) {
      msg = (
        <p style={customStyle.errorMessage}>{this.state.internalMsg.content}</p>
      );
    } else if (
      this.state.internalMsg.triggered &&
      this.state.internalMsg.type === "success"
    ) {
      msg = (
        <p style={customStyle.successMessage}>
          {this.state.internalMsg.content}
        </p>
      );
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <p>
            <b>Profile</b>
          </p>
          <Form>
            {profileElement}
            <hr></hr>
          </Form>
          <p>
            <b>Change Password</b>
          </p>
          <Form onSubmit={(event) => this.submitHandler(event)}>
            {form}
            <Button type="submit">Submit</Button>
          </Form>
        </div>
      </>
    );
  }
}

export default Settings;
