import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import ModalElement from "../../../components/UI/Modal/Modal";

import { FormMapper, SysMapper, RevMapper } from "../../../hoc/FormConfig";
import { customStyle } from "../../../hoc/CustomStyle";
import axios from "axios";

import EditorBoard from "./EditorBoard/EditorBoard";
import ReviewForm from "./ReviewForm/ReviewForm";

class Reviews extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table, form, editorboard
      user_type: null,
    },

    table: {
      header: null,
      content: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    editorboard: {
      header: null,
      content: null,
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.backHandler = this.backHandler.bind(this);
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  loadpageHandler(content_type, content_id) {
    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      let params = {
        request_type: content_type,
        retrieve_id: content_id,
      };

      let url_sublink = "reviews";
      // if (this.props.grant_infor.grant_stage === 2) {
      //     url_sublink = 'reviews'
      // }

      if (params) {
        // table
        axios({
          url: process.env.REACT_APP_AXIOS_URL + url_sublink + "/fetch",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: params.request_type,
            retrieve_id: params.retrieve_id,
          },
        })
          .then((received) => {
            if (received.status === 200) {
              if (received.data.status) {
                let updatedStates = {};
                if (params.request_type === "table") {
                  updatedStates = {
                    ...this.state,
                    table: {
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    form: {
                      retrieve_id: null,
                      header: null,
                      content: null,
                    },
                    editorboard: {
                      header: null,
                      content: null,
                    },
                  };
                } else if (params.request_type === "form") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                    },
                    editorboard: {
                      header: null,
                      content: null,
                    },
                  };
                } else if (params.request_type === "editorboard") {
                  updatedStates = {
                    ...this.state,
                    editorboard: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                    },
                    form: {
                      retrieve_id: null,
                      header: null,
                      content: null,
                    },
                  };
                }

                updatedStates = {
                  ...updatedStates,
                  render: {
                    loaded: true,
                    content_type: params.request_type,
                    user_type: received.data.payload.user_type,
                  },
                };

                this.setState(updatedStates);
              } else {
                this.messageHandler("error", received.data.message);
              }
            } else {
              this.messageHandler("error", "error: response not 200");
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.messageHandler(
                "error",
                "error: in communication -> " + error
              );
            }
          });
      } else {
        this.messageHandler("error", "error: invalid parameters");
      }
    } else {
      this.messageHandler("error", "error: missing token or expired");
    }
  }

  downloadHandler(type, submission_id) {
    const FileDownload = require("js-file-download");
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "download/file",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          submission_id: submission_id,
          download_type: type,
        },
        responseType: "arraybuffer",
      }).then((received) => {
        FileDownload(received.data, received.headers["content-type"]);
      });
    }
  }

  backHandler() {
    this.loadpageHandler("table", null);
  }

  assignHandler(submission_id) {
    this.loadpageHandler("editorboard", submission_id);
  }

  reviewHandler(submission_id) {
    this.loadpageHandler("form", submission_id);
  }

  componentDidMount() {
    this.loadpageHandler("table", null);
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    let header = <b>Reviews</b>;
    let content = <p>Loading ...</p>;

    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // load display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        const ordered_headers = this.sortByOrder(this.state.table.header);

        let num_columns = 0;
        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            if (entry.display === "table" || entry.display === "both") {
              num_columns++;
              return <th key={key}>{entry.name}</th>;
            }
          }
        );

        let table_content = (
          <tr>
            <td colSpan={num_columns}>No submission records found ...</td>
          </tr>
        );
        if (this.state.table.content.length > 0) {
          table_content = this.state.table.content.map((element, idx) => {
            let modal_content = {};
            let action_item = <p>N/A</p>;

            for (const key in ordered_headers) {
              // generate modal content
              if (
                ordered_headers[key].display === "modal" ||
                ordered_headers[key].display === "both"
              ) {
                modal_content[key] = {
                  value: element["primary"][key],
                  type: ordered_headers[key].style,
                  header: ordered_headers[key].name,
                };

                if (ordered_headers[key].style === "bullet") {
                  let arrayContent = [];
                  let tokenArray = element["primary"][key].split(",");

                  for (var i = 0; i < tokenArray.length; i++) {
                    arrayContent.push(FormMapper[tokenArray[i]]);
                  }
                  modal_content[key]["value"] = arrayContent;
                } else if (ordered_headers[key].style === "file") {
                  modal_content[key]["value"] = () =>
                    this.downloadHandler(
                      key,
                      element["primary"]["submission_id"]
                    );
                } else if (key === "host_institution") {
                  modal_content[key]["value"] =
                    SysMapper[element["primary"][key]];
                }
              }

              // generate action button
              if (ordered_headers[key].type === "action") {
                if (this.state.render.user_type === "admin-super") {
                  action_item = (
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() =>
                        this.assignHandler(element["primary"]["submission_id"])
                      }
                    >
                      Assign ({element["primary"][key]})
                    </Button>
                  );
                } else if (this.state.render.user_type === "reviewer-local") {
                  if (element["primary"][key] === "assigned") {
                    action_item = (
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() =>
                          this.reviewHandler(
                            element["primary"]["submission_id"]
                          )
                        }
                      >
                        Edit Review
                      </Button>
                    );
                  } else {
                    const ordered_revheaders = this.sortByOrder(
                      element["secondary"]["header"]
                    );

                    let view_review = {
                      title: {
                        value:
                          "Review #" +
                          element["secondary"]["content"]["submission_id"],
                        type: "text",
                        header: "Title",
                      },
                    };
                    for (let modalKey in ordered_revheaders) {
                      let value = element["secondary"]["content"][modalKey];
                      if (
                        element["secondary"]["header"][modalKey].style ===
                        "select"
                      )
                        value = "" + value + " - " + RevMapper[modalKey][value];

                      view_review[modalKey] = {
                        value: value,
                        type: element["secondary"]["header"][modalKey].style,
                        header: element["secondary"]["header"][modalKey].name,
                      };
                    }

                    action_item = (
                      <ModalElement
                        key={idx}
                        displayMode="display"
                        message={view_review}
                      />
                    );
                  }
                } else if (this.state.render.user_type === "reviewer-inter") {
                  if (element["primary"][key] === "assigned") {
                    action_item = (
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() =>
                          this.reviewHandler(
                            element["primary"]["submission_id"]
                          )
                        }
                      >
                        Edit Review
                      </Button>
                    );
                  } else {
                    const ordered_revheaders = this.sortByOrder(
                      element["secondary"]["header"]
                    );

                    let view_review = {
                      title: {
                        value:
                          "Review #" +
                          element["secondary"]["content"]["submission_id"],
                        type: "text",
                        header: "Title",
                      },
                    };
                    for (let modalKey in ordered_revheaders) {
                      let value = element["secondary"]["content"][modalKey];
                      if (
                        element["secondary"]["header"][modalKey].style ===
                        "select"
                      )
                        value = "" + value + " - " + RevMapper[modalKey][value];

                      view_review[modalKey] = {
                        value: value,
                        type: element["secondary"]["header"][modalKey].style,
                        header: element["secondary"]["header"][modalKey].name,
                      };
                    }

                    action_item = (
                      <ModalElement
                        key={idx}
                        displayMode="display"
                        message={view_review}
                      />
                    );
                  }
                }
              }
            }

            const modalElement = (
              <ModalElement
                key={idx}
                displayMode="display"
                message={modal_content}
              />
            );
            const rowElement = Object.entries(ordered_headers).map(
              ([key, entry], row_idx) => {
                if (entry.display === "table" || entry.display === "both") {
                  if (key === "title") {
                    return (
                      <td key={row_idx} style={customStyle.wordBreakTwo}>
                        {modalElement}
                      </td>
                    );
                  } else if (key === "action") {
                    return <td key={row_idx}>{action_item}</td>;
                  } else if (key === "status") {
                    return (
                      <td key={row_idx}>
                        {SysMapper[element["primary"][key]]}
                      </td>
                    );
                  } else {
                    return <td key={row_idx}>{element["primary"][key]}</td>;
                  }
                }
              }
            );

            return <tr key={idx}>{rowElement}</tr>;
          });
        }

        if (this.state.render.user_type === "admin-super") {
          header = (
            <>
              <Row>
                <Col sm={10}>
                  To download all reviews and submissions, click the button on
                  the right
                </Col>
                <Col sm={2}>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => this.downloadHandler("all", null)}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </>
          );
        }

        content = (
          <>
            <Table striped bordered>
              <thead>
                <tr>{table_header}</tr>
              </thead>
              <tbody>{table_content}</tbody>
            </Table>
          </>
        );
      } else if (this.state.render.content_type === "form") {
        header = <b>Review Form</b>;
        content = (
          <ReviewForm
            grant_infor={this.props.grant_infor}
            review_infor={this.state.form}
            backHandler={this.backHandler}
          />
        );
      } else if (this.state.render.content_type === "editorboard") {
        header = <b>EditorBoard</b>;
        content = (
          <EditorBoard
            grant_infor={this.props.grant_infor}
            review_infor={this.state.editorboard}
            backHandler={this.backHandler}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>{content}</Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Reviews;
