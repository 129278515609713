import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import Auth from "./containers/Auth/Auth";
import Register from "./containers/Auth/Register";
import ForgetLogin from "./containers/Auth/ForgetLogin";
import Recovery from "./containers/Auth/Recovery";

// import ViewSub from './containers/Dashboard/Submissions/ViewSub';
import Dashboard from "./containers/Dashboard/Dashboard";

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Auth} />
            <Route path="/register" component={Register} />
            <Route path="/forgetlogin" component={ForgetLogin} />
            <Route path="/recovery" component={Recovery} />

            <Route path="/dashboard" component={Dashboard} />
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
