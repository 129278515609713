import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import queryString from "query-string";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Input from "../../components/UI/Input/Input";
import Aux from "../../hoc/Auxx/Auxx";
import { checkValidity, checkMatchValidity } from "../../hoc/Util";
import { customStyle } from "../../hoc/CustomStyle";

// inBuilt bootstrap style
const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class Recovery extends Component {
  state = {
    controls: {
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },
        label: {
          main: "Password",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "",
        },
        value: "",
        valid: false,
        touched: false,
      },
      confirmPassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Confirm Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Confirm Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
          maxLength: 200,
          required: true,
        },
        label: {
          main: "Confirm Password",
          sub: "",
        },
        feedback: {
          status: "valid",
          message: "",
        },
        value: "",
        valid: false,
        touched: false,
      },
    },

    loading: false,

    message: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  toggleLoading(status) {
    this.setState({ ...this.state, loading: status });
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = null;

    if (controlName === "confirmEmail") {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["email"].value,
        "Emails"
      );
    } else if (controlName === "confirmPassword") {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["password"].value,
        "Passwords"
      );
    } else if (
      controlName === "password" &&
      this.state.controls["confirmPassword"].value.length > 0
    ) {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["confirmPassword"].value,
        "Passwords"
      );
    } else if (
      controlName === "email" &&
      this.state.controls["confirmEmail"].value.length > 0
    ) {
      validateOutput = checkMatchValidity(
        event.target.value,
        this.state.controls["confirmEmail"].value,
        "Passwords"
      );
    } else {
      validateOutput = checkValidity(
        event.target.value,
        this.state.controls[controlName].validation
      );
    }

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  messageHandler(msg_type, msg_content) {
    this.setState({
      ...this.state,
      message: {
        triggered: true,
        type: msg_type,
        content: msg_content,
      },
    });
  }

  submitHandler = (event, payload) => {
    event.preventDefault();

    this.toggleLoading(true);

    if (
      this.state.controls.password.valid &&
      this.state.controls.confirmPassword.valid
    ) {
      axios
        .post(process.env.REACT_APP_AXIOS_URL + "forgetlogin/recovery", {
          recovery_token: payload,
          recovery_pass: this.state.controls.password.value,
        })
        .then((received) => {
          this.toggleLoading(false);
          if (received.status === 200) {
            if (received.data.status) {
              this.messageHandler("success", received.data.message);
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler("error", "response not 200");
          }
        })
        .catch((error) => {
          this.toggleLoading(false);
          this.messageHandler("error", "unexpected error: " + error);
        });
    } else {
      this.toggleLoading(false);
    }
  };

  render() {
    // handling redirect
    let params = queryString.parse(this.props.location.search);
    if (!params["token"]) {
      // missing token
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              messsage: {
                type: "error",
                content: "Invalid or missing token",
              },
            },
          }}
        />
      );
    } else if (this.state.message.triggered) {
      // some error occurred
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: this.state.message.type,
                content: this.state.message.content,
              },
            },
          }}
        />
      );
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        elementDecorators={formElement.config.elementDecorators}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
        feedback={formElement.config.feedback}
      />
    ));

    return (
      <Aux>
        <NavigationItems login={false} />
        <Container>
          <Row>
            <Col md={controlClasses.frame.col}>
              <Card style={customStyle.topBuffer30}>
                <Card.Header>
                  <b>Change Password</b>
                </Card.Header>
                <Card.Body>
                  <Form
                    onSubmit={(event) =>
                      this.submitHandler(event, params["token"])
                    }
                  >
                    {form}
                    {this.state.loading ? (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                          size="sm"
                        />
                        Recovering...
                      </Button>
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </Form>
                </Card.Body>
                <Card.Footer></Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

export default Recovery;
