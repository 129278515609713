import React from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { customStyle } from "../../../../hoc/CustomStyle";

const DynamicInput = (props) => {
  /*
        args: props.element
    */

  let componentContent = <p>Coming Soon</p>;

  if (props.element.elementConfig.type === "table-fixed") {
    const rowMapper = props.element.elementConfig.data.map;
    const rowHeaders = props.element.elementConfig.data.input;
    const rowElements = props.element.value;

    let table_header = Object.entries(rowHeaders).map(
      ([colKey, colElement], idx) => {
        return <th key={colKey + idx.toString()}>{colElement.label}</th>;
      }
    );

    let table_element = rowElements.map((rowElement, rowIdx) => {
      let render_result = Object.entries(rowElement).map(
        ([colKey, colEntry], colIdx) => {
          if (colEntry.config.type === "display") {
            return (
              <td key={colKey + colIdx.toString()}>
                {rowMapper[colEntry.value].name}
              </td>
            );
          } else {
            let config_type = "input";
            if (
              colEntry.config.type === "select" ||
              colEntry.config.type === "textarea"
            )
              config_type = colEntry.config.type;

            return (
              <td key={colKey + colIdx.toString()}>
                <Form.Group>
                  <Form.Control
                    as={config_type}
                    {...colEntry.config}
                    value={colEntry.value}
                    isValid={colEntry.valid && colEntry.touched ? true : false}
                    isInvalid={
                      !colEntry.valid && colEntry.touched ? true : false
                    }
                    required={colEntry.config.required}
                    onChange={(event) =>
                      colEntry.handler(event, props.controlName, colKey, rowIdx)
                    }
                  >
                    {colEntry.config.options
                      ? colEntry.config.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type={colEntry.feedback.type}>
                    {colEntry.feedback.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            );
          }
        }
      );

      return <tr key={"element" + rowIdx.toString()}>{render_result}</tr>;
    });

    componentContent = (
      <>
        <Table striped bordered>
          <thead>
            <tr>{table_header}</tr>
          </thead>
          <tbody>{table_element}</tbody>
        </Table>
      </>
    );
  } else if (props.element.elementConfig.type === "stack") {
    const rowElements = props.element.value;

    // receive: props.header, props.content (array of objects)
    let table_element = rowElements.map((rowElement, rowIdx) => {
      let render_result = Object.entries(rowElement).map(
        ([colKey, colEntry], colIdx) => {
          if (colKey !== "handler") {
            if (colEntry.config.type === "select") {
              return (
                <Form.Group key={colKey + colIdx.toString()} as={Row}>
                  <Form.Label column sm={3}>
                    <p style={customStyle.subHeader}>{colEntry.label}</p>
                    <p style={customStyle.subText}>{colEntry.sublabel}</p>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as={colEntry.config.type}
                      value={colEntry.value}
                      isValid={
                        colEntry.valid && colEntry.touched ? true : false
                      }
                      isInvalid={
                        !colEntry.valid && colEntry.touched ? true : false
                      }
                      required={colEntry.config.required}
                      onChange={(event) =>
                        colEntry.handler(
                          event,
                          props.controlName,
                          colKey,
                          rowIdx
                        )
                      }
                    >
                      {colEntry.config.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type={colEntry.feedback.type}>
                      {colEntry.feedback.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              );
            } else if (colEntry.config.type === "textarea") {
              return (
                <Form.Group key={colKey + colIdx.toString()} as={Row}>
                  <Form.Label column sm={3}>
                    <p style={customStyle.subHeader}>{colEntry.label}</p>
                    <p style={customStyle.subText}>{colEntry.sublabel}</p>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as={colEntry.config.type}
                      value={colEntry.value}
                      isValid={
                        colEntry.valid && colEntry.touched ? true : false
                      }
                      isInvalid={
                        !colEntry.valid && colEntry.touched ? true : false
                      }
                      required={colEntry.config.required}
                      placeholder={colEntry.config.placeholder}
                      onChange={(event) =>
                        colEntry.handler(
                          event,
                          props.controlName,
                          colKey,
                          rowIdx
                        )
                      }
                    />
                    <Form.Control.Feedback type={colEntry.feedback.type}>
                      {colEntry.feedback.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              );
            } else {
              return (
                <Form.Group key={colKey + colIdx.toString()} as={Row}>
                  <Form.Label column sm={3}>
                    <p style={customStyle.subHeader}>{colEntry.label}</p>
                    <p style={customStyle.subText}>{colEntry.sublabel}</p>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      {...colEntry.config}
                      value={colEntry.value}
                      isValid={
                        colEntry.valid && colEntry.touched ? true : false
                      }
                      isInvalid={
                        !colEntry.valid && colEntry.touched ? true : false
                      }
                      required={colEntry.config.required}
                      onChange={(event) =>
                        colEntry.handler(
                          event,
                          props.controlName,
                          colKey,
                          rowIdx
                        )
                      }
                    />
                    <Form.Control.Feedback type={colEntry.feedback.type}>
                      {colEntry.feedback.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              );
            }
          } else {
            return <></>;
          }
        }
      );

      return (
        <tr key={"element" + rowIdx.toString()}>
          <td>{render_result}</td>
          <td style={customStyle.center}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(event) =>
                rowElement.handler(event, props.controlName, rowIdx)
              }
            >
              (-)
            </Button>
          </td>
        </tr>
      );
    });

    // XXX Refactored out in case we can control whether to show the
    // button or not.
    let buttonContent = (
      <Button variant="outline-success" size="sm" onClick={props.onadd}>
        (+)
      </Button>
    );
    componentContent = (
      <>
        <Table striped bordered>
          <tbody>{table_element}</tbody>
          <tfoot>
            <tr>
              <td>Click on the button on the right to add rows</td>
              <th style={customStyle.center}>{buttonContent}</th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  } else if (props.element.elementConfig.type === "stack-infused") {
    const infused_element = (
      <Form.Group>
        <Form.Control
          {...props.element.infusion.config}
          value={props.element.infusion.value}
          isValid={props.element.infusion.valid ? true : false}
          isInvalid={props.element.infusion.valid ? false : true}
          onChange={props.changed}
        />
        <p style={customStyle.subText}>{props.element.infusion.label}</p>
        <Form.Control.Feedback type={props.element.infusion.feedback.type}>
          {props.element.infusion.feedback.message}
        </Form.Control.Feedback>
      </Form.Group>
    );

    const rowElements = props.element.value;

    // receive: props.header, props.content (array of objects)
    let table_element = rowElements.map((rowElement, rowIdx) => {
      let render_result = Object.entries(rowElement).map(
        ([colKey, colEntry], colIdx) => {
          if (colKey !== "handler") {
            if (colEntry.config.type === "select") {
              return (
                <Form.Group key={colKey + colIdx.toString()} as={Row}>
                  <Form.Label column sm={3}>
                    <p style={customStyle.subHeader}>{colEntry.label}</p>
                    <p style={customStyle.subText}>{colEntry.sublabel}</p>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as={colEntry.config.type}
                      value={colEntry.value}
                      isValid={
                        colEntry.valid && colEntry.touched ? true : false
                      }
                      isInvalid={
                        !colEntry.valid && colEntry.touched ? true : false
                      }
                      required={colEntry.config.required}
                      onChange={(event) =>
                        colEntry.handler(
                          event,
                          props.controlName,
                          colKey,
                          rowIdx
                        )
                      }
                    >
                      {colEntry.config.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type={colEntry.feedback.type}>
                      {colEntry.feedback.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              );
            } else if (colEntry.config.type === "textarea") {
              return (
                <Form.Group key={colKey + colIdx.toString()} as={Row}>
                  <Form.Label column sm={3}>
                    <p style={customStyle.subHeader}>{colEntry.label}</p>
                    <p style={customStyle.subText}>{colEntry.sublabel}</p>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as={colEntry.config.type}
                      value={colEntry.value}
                      isValid={
                        colEntry.valid && colEntry.touched ? true : false
                      }
                      isInvalid={
                        !colEntry.valid && colEntry.touched ? true : false
                      }
                      required={colEntry.config.required}
                      placeholder={colEntry.config.placeholder}
                      onChange={(event) =>
                        colEntry.handler(
                          event,
                          props.controlName,
                          colKey,
                          rowIdx
                        )
                      }
                    />
                    <Form.Control.Feedback type={colEntry.feedback.type}>
                      {colEntry.feedback.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              );
            } else {
              return (
                <Form.Group key={colKey + colIdx.toString()} as={Row}>
                  <Form.Label column sm={3}>
                    <p style={customStyle.subHeader}>{colEntry.label}</p>
                    <p style={customStyle.subText}>{colEntry.sublabel}</p>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      {...colEntry.config}
                      value={colEntry.value}
                      isValid={
                        colEntry.valid && colEntry.touched ? true : false
                      }
                      isInvalid={
                        !colEntry.valid && colEntry.touched ? true : false
                      }
                      required={colEntry.config.required}
                      onChange={(event) =>
                        colEntry.handler(
                          event,
                          props.controlName,
                          colKey,
                          rowIdx
                        )
                      }
                    />
                    <Form.Control.Feedback type={colEntry.feedback.type}>
                      {colEntry.feedback.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              );
            }
          } else {
            return <></>;
          }
        }
      );

      return (
        <tr key={"element" + rowIdx.toString()}>
          <td>{render_result}</td>
          <td style={customStyle.center}>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(event) =>
                rowElement.handler(event, props.controlName, rowIdx)
              }
            >
              (-)
            </Button>
          </td>
        </tr>
      );
    });

    componentContent = (
      <>
        <Table striped bordered>
          <thead>
            <tr>
              <td>{infused_element}</td>
              <th></th>
            </tr>
          </thead>
          <tbody>{table_element}</tbody>
          <tfoot>
            <tr>
              <td>Click on the button on the right to add rows</td>
              <th style={customStyle.center}>
                <Button
                  variant="outline-success"
                  size="sm"
                  onClick={props.onadd}
                >
                  (+)
                </Button>
              </th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  }

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={3}>
          <b>{props.element.elementDecorators.label}</b>
          <p style={customStyle.subText}>
            {props.element.elementDecorators.sublabel}
          </p>
          <p style={customStyle.subText}>
            {props.element.elementDecorators.sublabeladd}
          </p>
        </Form.Label>
        <Col sm={8}>{componentContent}</Col>
      </Form.Group>
    </>
  );
};

export default DynamicInput;
