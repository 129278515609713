import React, { Component } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

// For handling al lthe funny number formats.
import { decimalFormatting } from "../../../../hoc/Util";

//styling
import { customStyle } from "../../../../hoc/CustomStyle";
import { FormMapper } from "../../../../hoc/FormConfig";

class SubmissionView extends Component {
  state = {
    render: {
      mode: "loaded", //loading
      mounted: false,
    },

    data: null,

    budget_grand: {
      grand_total: "Grand Total Cost ($S)",
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  numberWithCommas(x) {
    // Don't do this.  Use Intl.NumberFormat() to help us.
    //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalFormatting.format(x);
  }

  render() {
    let headers = this.sortByOrder(this.props.sub_infor.header);

    let Components = Object.entries(headers).map(([key, element], idx) => {
      let title = element.name;
      let content = this.props.sub_infor.content[key];

      let render = null;

      if (element.type === "list") {
        let list_items = content.map((value, idx) => {
          return (
            <li key={idx}>{FormMapper[value] ? FormMapper[value] : value}</li>
          );
        });

        render = <ul>{list_items}</ul>;
      } else if (element.type === "table") {
        let ordered_headers = this.sortByOrder(element.columns);

        /*
                    let table_header = Object.entries(ordered_headers).map(
                        ([table_key, table_value], idx) => {
                            return <th key="idx-{table_key}-{idx}">{table_value.name}</th>
                        }
                    )
                    */

        let table_content = content.map((data, idx) => {
          let rows = Object.entries(ordered_headers).map(
            ([row_key, row_value], idx) => {
              return (
                <>
                  <tr>
                    <th>{row_value.name}</th>
                    <td>
                      {FormMapper[data[row_key]]
                        ? FormMapper[data[row_key]]
                        : data[row_key]}
                    </td>
                  </tr>
                </>
              );
            }
          );

          return (
            <>
              <tr>
                <td colspan="2">
                  <b>
                    <i>Entry No.{idx + 1}</i>
                  </b>
                </td>
              </tr>
              {rows}
              <br></br>
            </>
          );
        });

        render = (
          <Table striped bordered responsive>
            {table_content}
          </Table>
        );
      } else if (element.type === "file") {
        let render_btn = content ? true : false;

        render = render_btn ? (
          <Button
            variant="primary"
            onClick={() =>
              this.props.downloadHandler(
                // We need to specially identify each of these components.
                (key !== "submission" && key !== "submission-review"
                  ? "submission_component-"
                  : "") + key,
                this.props.sub_infor.content["submission_id"]
              )
            }
          >
            Download
          </Button>
        ) : (
          <p>N/A</p>
        );
      } else {
        render = FormMapper[content] ? FormMapper[content] : content;
      }

      return (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              <b>{title}</b>
            </Form.Label>
            <Col sm={8}>{render}</Col>
          </Form.Group>
          {element.type === "table" ? <hr></hr> : null}
        </>
      );
    });

    return (
      <>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>
        <div style={customStyle.topBuffer20}>{Components}</div>
        <Button variant="info" onClick={() => this.props.formHandler("back")}>
          Back
        </Button>
      </>
    );
  }
}

export default SubmissionView;
