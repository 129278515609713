import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import { RevMapper } from "../../../../hoc/FormConfig";
import ModalElement from "../../../../components/UI/Modal/Modal";

import { customStyle } from "../../../../hoc/CustomStyle";
import axios from "axios";

class EditorBoard extends Component {
  state = {
    render: {
      loaded: false,
    },

    table: {
      header: null,
      content: null,
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      table: this.props.review_infor,
      render: {
        loaded: true,
      },
    });
  }

  fetchHandler() {
    let accessToken = localStorage.getItem("access_token");

    let url_sublink = "reviews";
    // if (this.props.grant_infor.grant_stage === 2){
    //     url_sublink = 'reviews';
    // }

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + url_sublink + "/fetch",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: "editorboard",
          retrieve_id: this.state.table.content["submission_id"],
        },
      })
        .then((received) => {
          if (received.status === 200) {
            if (received.data.status) {
              this.setState({
                ...this.state,
                table: received.data.payload,
              });
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler("error", "error: response not 200");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler("error", "error: in communication -> " + error);
          }
        });
    } else {
      this.messageHandler("error", "error: missing token or expired");
    }
  }

  postHandler(content_type, content_data) {
    let accessToken = localStorage.getItem("access_token");

    let url_sublink = "reviews";
    // if (this.props.grant_infor.grant_stage === 2) {
    //     url_sublink = 'reviews';
    // }

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + url_sublink + "/post",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          post_type: content_type,
          post_data: content_data,
        },
      })
        .then((received) => {
          if (received.status === 200) {
            if (received.data.status) {
              this.fetchHandler();
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler("error", "error: response not 200");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler("error", "error: in communication -> " + error);
          }
        });
    } else {
      this.messageHandler("error", "error: missing token or expired");
    }
  }

  assignTriggerHandler(user_id) {
    this.postHandler("assign", {
      submission_id: this.state.table.content["submission_id"],
      user_id: user_id,
      grant_id: this.props.grant_infor.grant_id,
    });
  }

  unassignTriggerHandler(user_id) {
    this.postHandler("unassign", {
      submission_id: this.state.table.content["submission_id"],
      user_id: user_id,
      grant_id: this.props.grant_infor.grant_id,
    });
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    let msg = null;
    if (
      this.state.internalMsg.triggered &&
      this.state.internalMsg.type === "error"
    ) {
      msg = (
        <p style={customStyle.errorMessage}>{this.state.internalMsg.content}</p>
      );
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    let content = <p>Loading ...</p>;

    if (this.state.render.loaded) {
      let top_header = (
        <>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column>
                {this.state.table.header["submission_id"].name}
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Control
                readOnly
                defaultValue={this.state.table.content["submission_id"]}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column>
                {this.state.table.header["title"].name}
              </Form.Label>
            </Col>
            <Col md={8}>
              <Form.Control
                readOnly
                defaultValue={this.state.table.content["title"]}
              />
            </Col>
          </Form.Group>
        </>
      );

      const ordered_headers = this.sortByOrder(
        this.state.table.header["reviewers"]
      );
      let num_columns = 0;
      const table_header = Object.entries(ordered_headers).map(
        ([key, entry], idx) => {
          if (entry.type === "hidden") return null;
          else {
            num_columns++;
            return <th key={key}>{entry.name}</th>;
          }
        }
      );

      let table_content = (
        <tr>
          <td colSpan={num_columns}>No reviewers records found ...</td>
        </tr>
      );
      if (this.state.table.content["reviewers"].length > 0) {
        table_content = this.state.table.content["reviewers"].map(
          (element, idx) => {
            let action_item = <p>N/A</p>;
            for (const key in ordered_headers) {
              // generate action button
              if (ordered_headers[key].type === "action") {
                if (element["status"] === "assigned") {
                  action_item = (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() =>
                        this.unassignTriggerHandler(element["user_id"])
                      }
                    >
                      Unassign
                    </Button>
                  );
                } else if (element["status"] === "unassigned") {
                  action_item = (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() =>
                        this.assignTriggerHandler(element["user_id"])
                      }
                    >
                      Assign
                    </Button>
                  );
                } else {
                  const ordered_revheaders = this.sortByOrder(
                    element["action"]["header"]
                  );

                  let view_review = {
                    title: {
                      value: "View Review",
                      type: "text",
                      header: "Title",
                    },
                  };
                  for (let modalKey in ordered_revheaders) {
                    let value = element["action"]["content"][modalKey];
                    if (
                      element["action"]["header"][modalKey].style === "select"
                    ) {
                      value = "" + value + " - " + RevMapper[modalKey][value];
                    }

                    view_review[modalKey] = {
                      value: value,
                      type: element["action"]["header"][modalKey].style,
                      header: element["action"]["header"][modalKey].name,
                    };
                  }

                  action_item = (
                    <ModalElement
                      key={idx}
                      displayMode="display"
                      message={view_review}
                    />
                  );
                }
              }
            }

            const rowElement = Object.entries(ordered_headers).map(
              ([key, entry], row_idx) => {
                if (entry.type === "hidden") {
                  return null;
                } else {
                  if (entry.type === "action")
                    return (
                      <td key={row_idx} style={customStyle.wordBreak}>
                        {action_item}
                      </td>
                    );
                  else
                    return (
                      <td key={row_idx} style={customStyle.wordBreak}>
                        {element[key]}
                      </td>
                    );
                }
              }
            );

            return <tr key={idx}>{rowElement}</tr>;
          }
        );
      }

      content = (
        <>
          {top_header}
          <hr></hr>
          <h5>List of Reviewers</h5>
          <Table striped bordered className={customStyle.tableCustom}>
            <thead>
              <tr>{table_header}</tr>
            </thead>
            <tbody>{table_content}</tbody>
          </Table>
        </>
      );
    }

    return (
      <>
        {msg}
        <Button variant="info" onClick={() => this.props.backHandler()}>
          Back
        </Button>
        <div>{content}</div>
      </>
    );
  }
}

export default EditorBoard;
