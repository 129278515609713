import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import Input from "../../../../components/UI/Input/Input";

import { checkValidity } from "../../../../hoc/Util";
import { customStyle } from "../../../../hoc/CustomStyle";
import { reviews } from "../../../../hoc/FormConfig";
import axios from "axios";

class ReviewForm extends Component {
  state = {
    controls: null,

    render: {
      loaded: true,
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.setState({
      ...this.state,
      reviewerAckCoi: false,
    });
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  toggleSpinner(trigger) {
    this.setState({
      ...this.state,
      render: {
        loaded: trigger,
      },
    });
  }

  ackCoi() {
    this.setState({
      ...this.state,
      reviewerAckCoi: !this.state.reviewerAckCoi,
    });
  }

  componentDidMount() {
    let updatedControls = null;
    updatedControls = reviews;

    for (let elementKey in this.props.review_infor.content) {
      if (updatedControls.hasOwnProperty(elementKey)) {
        updatedControls[elementKey].value =
          this.props.review_infor.content[elementKey];

        if (updatedControls[elementKey].value !== "") {
          let validateResult = checkValidity(
            updatedControls[elementKey].value,
            updatedControls[elementKey].validation
          );
          updatedControls[elementKey].valid = validateResult.isValid;
          updatedControls[elementKey].elementDecorators.feedbackMsg =
            validateResult.message;
          updatedControls[elementKey].touched = true;
        } else {
          updatedControls[elementKey].touched = false;
        }
      }
    }

    this.setState({ controls: updatedControls });
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };
    this.setState({ controls: updatedControls });
  };

  postHandler(event, type) {
    event.preventDefault();

    this.toggleSpinner(false);

    let validated = true;
    let values = {};

    values["submission_id"] = this.props.review_infor.content["submission_id"];

    Object.entries(this.state.controls).forEach(([formKey, formEntry], idx) => {
      if (validated && !formEntry.valid) {
        validated = false;
        //console.log(formKey + " is invalid!");
      }
      values[formKey] = formEntry.value;
    });

    if (type === "save") validated = true;

    if (validated) {
      let url_link = "reviews";
      // if (this.props.grant_infor.grant_stage === 2) {
      //     url_link = 'reviews'
      // }

      let accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + url_link + "/post",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            post_type: type,
            post_data: values,
          },
        })
          .then((received) => {
            this.toggleSpinner(true);

            if (received.status === 200) {
              if (received.data.status) {
                if (type !== "save") {
                  this.props.backHandler();
                }
              } else {
                this.setState({
                  ...this.state,
                  internalMsg: {
                    triggered: true,
                    type: "error",
                    content: received.data.message,
                  },
                });
              }
            } else {
              this.setState({
                ...this.state,
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "received state not 200",
                },
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.toggleSpinner(true);

              this.setState({
                ...this.state,
                internalMsg: {
                  triggered: true,
                  type: "error",
                  content: "critical error: " + error,
                },
              });
            }
          });
      }
    } else {
      this.toggleSpinner(true);
    }
  }

  submitWrapper(event, confirmMsg) {
    event.preventDefault();
    if (window.confirm(confirmMsg) === true) {
      this.postHandler(event, "submit");
    }
  }

  render() {
    let msg = null;
    if (
      this.state.internalMsg.triggered &&
      this.state.internalMsg.type === "error"
    ) {
      msg = (
        <p style={customStyle.errorMessage}>{this.state.internalMsg.content}</p>
      );
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // Refactoring and adjusting (because why not).
    const form_ = { a: [], b: [], c: [], d: [] };
    for (let key in this.state.controls) {
      var section_key = this.state.controls[key].section;
      if (form_.hasOwnProperty(section_key)) {
        // Special case the section with multi-sub-section parts.
        if (section_key === "a") {
          if (form_[section_key].length - 1 < this.state.controls[key].part) {
            form_[section_key].push([
              {
                id: key,
                config: this.state.controls[key],
              },
            ]);
          } else {
            form_[section_key][this.state.controls[key].part].push({
              id: key,
              config: this.state.controls[key],
            });
          }
        } else {
          form_[section_key].push({
            id: key,
            config: this.state.controls[key],
          });
        }
      }
    }

    // Refactored mapper.
    const f_ = (formElement) => {
      return (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          value={formElement.config.value}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          elementDecorators={formElement.config.elementDecorators}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={(event) => this.inputChangedHandler(event, formElement.id)}
        />
      );
    };

    const section_ = {
      a: form_["a"].map((segment, idx) => {
        let sub_header = null;
        if (idx === 0) {
          sub_header = (
            <p style={customStyle.sectionSubText}>Evaluation Criteria</p>
          );
        } else if (idx === 1) {
          sub_header = (
            <p style={customStyle.sectionSubText}>Resources Requested</p>
          );
        }

        let sub_seg = segment.map((subElement, idx) => {
          return (
            <Input
              key={subElement.id}
              label={subElement.config.label}
              value={subElement.config.value}
              elementType={subElement.config.elementType}
              elementConfig={subElement.config.elementConfig}
              elementDecorators={subElement.config.elementDecorators}
              invalid={!subElement.config.valid}
              shouldValidate={subElement.config.validation}
              touched={subElement.config.touched}
              changed={(event) =>
                this.inputChangedHandler(event, subElement.id)
              }
            />
          );
        });

        return (
          <>
            <div style={customStyle.sectionBorderNorm}>
              {sub_header}
              {sub_seg}
            </div>
            <br></br>
          </>
        );
      }),
      b: form_["b"].map(f_),
      c: form_["c"].map(f_),
      d: form_["d"].map(f_),
    };

    // FIXME This is a HACK.  We should really be populating annexes and other things via the database, not through messing with things like this!
    const annex = (
      <>
        <div style={customStyle.sectionBorderAnnex}>
          {/*<h5>Annex</h5>*/}

          <Table>
            <thead>
              <tr>
                <th colSpan={2}>
                  List of Visions and Research Topics of the Proposal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="50%">
                  <b>1. Discriminating Information</b>
                </td>
                <td width="50%">
                  <b>2. Towards Human Omnitasking</b>
                </td>
              </tr>
              <tr>
                <td>
                  &ndash;&nbsp;Information authentication, bias, benchmarks, and
                  evaluation
                  <br />
                  &ndash;&nbsp;Interpretation and generation
                  <br />
                  &ndash;&nbsp;Assessment of impact and user profiling
                  <br />
                </td>
                <td>
                  &ndash;&nbsp;Multimodal understanding and summarization /
                  Conversational NLP
                  <br />
                  &ndash;&nbsp;Cognitive state tracking / Commonsense reasoning
                  <br />
                  &ndash;&nbsp;Behavior understanding / Personalization
                  <br />
                  &ndash;&nbsp;Trustworthy and explainable AI / Human AI
                  collaboration
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <b>3. Removing Unwanted Digital Footprints</b>
                </td>
                <td>
                  <b>4. AI for Science</b>
                </td>
              </tr>
              <tr>
                <td>
                  &ndash;&nbsp;Definition of unwanted footprints
                  <br />
                  &ndash;&nbsp;Footprint removal algorithms
                  <br />
                  &ndash;&nbsp;Footprint removal auditing
                  <br />
                  &ndash;&nbsp;Evaluation of organizational trustworthiness
                  <br />
                </td>
                <td>
                  &ndash;&nbsp;AI for natural law extraction and understanding
                  <br />
                  &ndash;&nbsp;Foundational AI models for fast, accurate,
                  large-scale scientific discovery
                  <br />
                  &ndash;&nbsp;AI for inverse design in science
                  <br />
                  &ndash;&nbsp;AI that maximizes a priori knowledge
                  <br />
                  &ndash;&nbsp;AI for data-sparse science
                  <br />
                  &ndash;&nbsp;Learning represeantations for scientific
                  creativity
                  <br />
                  &ndash;&nbsp;AI for quantum science
                  <br />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th colSpan={2}>
                  List of AI Core Technical Areas of the Proposal
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="50%">A. Cognitive modelling and systems</td>
                <td width="50%">G. Natural language processing (NLP)</td>
              </tr>
              <tr>
                <td>B. Game theory and economic paradigms</td>
                <td>H. Planning and scheduling</td>
              </tr>
              <tr>
                <td>C. Heuristic search and optimization</td>
                <td>I. Reasoning under uncertainty</td>
              </tr>
              <tr>
                <td>D. Knowledge representation and reasoning</td>
                <td>J. Robotics</td>
              </tr>
              <tr>
                <td>E. Machine learning</td>
                <td>K. Search and constraint satisfaction</td>
              </tr>
              <tr>
                <td>F. Multiagent systems</td>
                <td>L. Vision</td>
              </tr>
            </tbody>
          </Table>
          <br></br>
          {/* <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colSpan={3}>List of AI Research Grant Call 2020 Themes and Sub-themes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Theme One: AI for Collaborative Decision Making</b></td>
                                <td><b>Theme Two: Trustworthy and Explainable AI</b></td>
                                <td><b>Theme Three: AI for Design and Discovery</b></td>
                            </tr>
                            <tr>
                                <td>i. Human Understanding and Interaction</td>
                                <td>i. Safe, Fair and Robust AI System Development and Evaluation</td>
                                <td>i. AI Understanding of Design and Creation</td>
                            </tr>
                            <tr>
                                <td>ii. Uncertainty and Scalability</td>
                                <td>ii. Transparent or Explainable AI System Development and Evaluation</td>
                                <td>ii. Human AI Co-Design</td>
                            </tr>
                            <tr>
                                <td>iii. Communications</td>
                                <td>iii. Explainability and Trust Assessment</td>
                                <td>iii. Knowledge Representation with Complex Priors or Measurements</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>iv. Science-Inspired or Physics-Based AI Systems and Models</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <b>Note:</b>
                                    <ul>
                                        <li>Proposals are supposed to address one of the three themes.</li>
                                        <li>No multiple main themes are allowed.</li>
                                        <li>Addressing multiple or new sub-themes under the main theme is allowed.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </Table>             */}
        </div>
      </>
    );

    const form = (
      <>
        <div style={customStyle.sectionBorderBlue}>
          <h5>Section A: Scoring/Rating of the Proposal</h5>
          <p style={customStyle.subText}>
            Note: The comments in this section will{" "}
            <b>
              <u>not</u>
            </b>{" "}
            be released to applicants
          </p>
          {section_["a"]}
        </div>
        <br></br>
        <div style={customStyle.sectionBorderBlue}>
          <h5>Section B: Overall Justification</h5>
          <p style={customStyle.subText}>
            Note: The comments in this section will{" "}
            <b>
              <u>not</u>
            </b>{" "}
            be released to applicants
          </p>
          {section_["b"]}
        </div>
        <br></br>
        <div style={customStyle.sectionBorderBlue}>
          <h5>Section C: Overall Scoring of Proposal</h5>
          <p style={customStyle.subText}>
            Note: The comments in this section will{" "}
            <b>
              <u>not</u>
            </b>{" "}
            be released to applicants
          </p>
          {section_["c"]}
        </div>
        <br></br>
        <div style={customStyle.sectionBorderRed}>
          <h5>Section D: Detailed Comments</h5>
          <p style={customStyle.subTextWarn}>
            Note: The comments in this section will be released to applicants
            directly
          </p>
          {section_["d"]}
        </div>
        <br></br>
        {annex}
        <br></br>
        <div>
          <h5> Declaration from Reviewer </h5>
          <p>
            By submitting this review,
            <ul>
              <li>
                I agree to keep confidential, and observe the proprietary and
                potential intellectual property rights of any material from the
                proposal(s).
              </li>
              <li>
                I affirm that I have no conflict of interest* with respect to
                the above-mentioned proposal(s).
                <p>
                  <br></br>*Conflicts of interest may include:
                  <ul>
                    <li>
                      Co-authoring/collaborated publications or articles with at
                      least one of the mentioned authors in the past 3 years
                    </li>
                    <li>
                      Being colleagues of the same section/department or similar
                      organisational unit in the last 3 years
                    </li>
                    <li>
                      Supervising/having supervised the doctoral work of the
                      author (s) or being supervised/having been supervised by
                      the author(s)
                    </li>
                    <li>
                      Having a personal relationship (e.g. family, close
                      friends) with the author(s)
                    </li>
                    <li>
                      Receiving professional or personal benefit resulting from
                      the review
                    </li>
                    <li>Having a direct or indirect financial interest</li>
                  </ul>
                </p>
              </li>
            </ul>
          </p>
        </div>
        <div>
          <label>
            <input type="checkbox" onChange={() => this.ackCoi()} />
            &nbsp;I have read and agree with the declaration.
          </label>
        </div>
      </>
    );

    let save_btn = (
      <Button
        variant="info"
        onClick={(event) => this.postHandler(event, "save")}
      >
        Save
      </Button>
    );
    let submit_btn = this.state.reviewerAckCoi && (
      <Button type="submit">Submit</Button>
    );
    let submit_msg = null;

    if (!this.state.render.loaded) {
      save_btn = (
        <Button variant="info" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Saving ...
        </Button>
      );

      submit_btn = (
        <Button variant="success" disabled>
          Submit
        </Button>
      );
      submit_msg = (
        <>
          <p style={customStyle.subConfirm}>
            <Spinner animation="border" size="sm" />
            Submitting your feedback... You will be automatically redirected
            upon successful submission, please do not refresh the page or hit
            the back button.
          </p>
        </>
      );
    }

    return (
      <>
        {msg}
        <p style={customStyle.subForeWord}>
          ** Please save your reviews regularly by using the Save button
        </p>
        <Button variant="warning" onClick={() => this.props.backHandler()}>
          Back
        </Button>{" "}
        {save_btn}
        <div style={customStyle.topBuffer20}>
          <Form
            onSubmit={(event) =>
              this.submitWrapper(
                event,
                "Confirm? You will not be able to modify the feedback after submission"
              )
            }
          >
            {form}
            {submit_btn} {save_btn}
            {/* As requested by perryb */}
            <p>
              You must{" "}
              <strong>
                tick the declaration tick-box then click the 'Submit' button
              </strong>{" "}
              to complete your review.
            </p>
          </Form>
          <br></br>
          {submit_msg}
        </div>
      </>
    );
  }
}

export default ReviewForm;
