import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import history from "../history";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Input from "../../components/UI/Input/Input";
import Aux from "../../hoc/Auxx/Auxx";

import { customStyle } from "../../hoc/CustomStyle";
import { checkValidity } from "../../hoc/Util";

// inBuilt bootstrap style
const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class Auth extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Email Address",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Email",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isEmail: true,
        },
        value: "",
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Password",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          minLength: 6,
        },

        value: "",
        valid: false,
        touched: false,
      },
      remember: {
        elementType: "checkbox",
        elementConfig: {
          required: false,
        },
        elementDecorators: {
          label: "Save login information?",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {},
        valid: false,
        touched: false,
      },
    },

    loading: false,

    errors: {
      triggered: false,
    },

    logout_error: {
      triggered: false,
      message: "",
    },
  };

  toggleLoading(status) {
    this.setState({ ...this.state, loading: status });
  }

  componentDidMount() {
    if (history.location.state && history.location.state.message) {
      let state = { ...history.location.state };
      delete state.message;
      history.replace({ ...history.location, state });
    }
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };
    this.setState({ controls: updatedControls });
  };

  // sending request over
  submitHandler = (event) => {
    event.preventDefault();

    this.toggleLoading(true);

    if (this.state.controls.email.valid && this.state.controls.password.valid) {
      axios
        .post(process.env.REACT_APP_AXIOS_URL + "auth/user", {
          email: this.state.controls.email.value,
          password: this.state.controls.password.value,
        })
        .then((received) => {
          this.toggleLoading(false);

          const login_status = received.data.status;
          const token = received.data.payload;

          if (login_status && token != null) {
            localStorage.setItem("access_token", token);
            this.setState({ errors: { triggered: false } });
            this.props.history.push("/dashboard");
          } else {
            this.setState({ errors: { triggered: true } });
          }
        });
    } else {
      this.toggleLoading(false);
    }
  };

  render() {
    // redirect if access_token exists
    if (localStorage.getItem("access_token")) {
      return <Redirect to="/dashboard" />;
    }

    // error handling
    let form_msg = this.state.errors.triggered ? (
      <p style={customStyle.errorMessage}>
        Invalid credentials, please try again
      </p>
    ) : null;

    let msg = null;
    if (this.props.location.state) {
      if (this.props.location.state.message) {
        if (this.props.location.state.message.type === "error") {
          msg = (
            <p className="text-center" style={customStyle.errorMessage}>
              {this.props.location.state.message.content}
            </p>
          );
        } else if (this.props.location.state.message.type === "success") {
          msg = (
            <p className="text-center" style={customStyle.successMessage}>
              {this.props.location.state.message.content}
            </p>
          );
        }
      }
    }

    // element handling
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        elementDecorators={formElement.config.elementDecorators}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    return (
      <Aux>
        <NavigationItems login={false} />
        <Container>
          <Row>
            <Col md={controlClasses.frame.col}>
              {msg}
              <Card style={customStyle.topBuffer30}>
                <Card.Header>
                  <b>Login</b>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={this.submitHandler}>
                    {form}
                    {form_msg}

                    {this.state.loading ? (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                          size="sm"
                        />
                        Signing in...
                      </Button>
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </Form>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <p>
                    Don't have an account? Click <a href={"/register/"}>here</a>{" "}
                    to register.
                  </p>
                  <p>
                    Forgot your login? Click <a href={"/forgetlogin/"}>here</a>{" "}
                    to retrieve them.
                  </p>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

export default withRouter(Auth);
