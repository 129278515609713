import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { customStyle } from "../../../../hoc/CustomStyle";

const FileInput = (props) => {
  let secondaryContent = null;
  if (!props.loaded) {
    secondaryContent = <Spinner animation="border" variant="primary" />;
  } else {
    if (props.value === "") {
      secondaryContent = <p>No file in our record</p>;
    } else {
      secondaryContent = (
        <Button variant="warning" onClick={props.downloadhandler}>
          Download Existing
        </Button>
      );
    }
  }

  let componentContent = (
    <>
      <Row>
        <Col sm={3}>
          {secondaryContent}
          <p
            style={
              props.valid
                ? customStyle.successMessage
                : customStyle.errorMessage
            }
          >
            {props.feedback !== "" ? props.feedback : "\u00A0"}
          </p>
        </Col>
        <Col sm={6} style={customStyle.bordered}>
          Upload/Reupload:
          <Form.Control type="file" onChange={props.uploadhandler} />
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={3}>
          <b>{props.label}</b>
          <p style={customStyle.subText}>{props.sublabel}</p>
        </Form.Label>
        <Col sm={8}>{componentContent}</Col>
      </Form.Group>
    </>
  );
};

export default FileInput;
