import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import ModalElement from "../../../components/UI/Modal/Modal";
//import GrantForm from './GrantForm/GrantForm';
import UserModificationForm from "./UserModificationForm";

import { customStyle } from "../../../hoc/CustomStyle";
import { SysMapper } from "../../../hoc/FormConfig";

import axios from "axios";

class Users extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table or form or submission,
    },

    table: {
      content: null,
      header: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.parentHandler = this.parentHandler.bind(this);
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  loadpageHandler(content_type, content_id) {
    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      let params = {
        request_type: content_type,
        retrieve_id: content_id,
      };

      if (params) {
        // table
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "/user/fetch/modify",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: params.request_type,
            request_id: params.retrieve_id,
          },
        })
          .then((received) => {
            if (received.status === 200) {
              if (received.data.status) {
                let updatedStates = {};
                if (params.request_type === "delete") {
                  updatedStates = {
                    ...this.state,
                    table: {
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                  };
                } else if (params.request_type === "form") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                      others: null,
                    },
                  };
                } else if (params.request_type === "modify") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                  };
                } else if (params.request_type === "view") {
                  updatedStates = {
                    ...this.state,
                    view: {
                      ...this.state.view,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      status: received.data.payload.outcome,
                    },
                  };
                }
                updatedStates = {
                  ...updatedStates,
                  render: {
                    loaded: true,
                    //content_type: "table",
                    content_type: params.request_type,
                  },
                };

                this.setState(updatedStates);
              } else {
                this.messageHandler("error", received.data.message);
              }
            } else {
              this.messageHandler(
                "error",
                "Unexpected error, contact site administrator if persist."
              );
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              console.log(error);
              this.messageHandler(
                "error",
                "An Unexpected error has occurred, please contact site admin if persists."
              );
            }
          });
      } else {
        this.messageHandler(
          "error",
          "Invalid parameters where parameters are expected"
        );
      }
    } else {
      this.messageHandler("expired", "Session expired, please login again");
    }

    // this.setState({
    //     ...this.state,
    //     render: {
    //         ...this.state.render,
    //         loaded: false
    //     }
    // })
  }

  renderTable() {
    // load table information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "user/fetch",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: "table",
          retrieve_id: null,
        },
      })
        .then((received) => {
          if (received.status === 200) {
            this.setState({
              ...this.state,
              render: {
                loaded: true,
                content_type: "table",
              },
              table: {
                ...this.state.table,
                content: received.data.payload.content,
                header: received.data.payload.header,
              },
            });
          } else {
            this.throwError(1001, "response not 200");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.throwError(1002, "critical error -> " + error.toString());
          }
        });
    } else {
      this.throwError(1003, "missing access token");
    }
  }

  parentHandler(mode) {
    if (mode === "back") {
      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          content_type: "table",
        },
      });
    } else if (mode === "submitted") {
      this.renderTable();
    }
  }

  throwError(code, reason) {
    var msg = "Error code G" + code.toString() + ":  " + reason;
    this.setState({
      ...this.state,
      internalMsg: {
        triggered: true,
        type: "error",
        content: msg,
      },
    });
  }

  componentDidMount() {
    this.renderTable();
  }

  onClickModifyHandler = (event, id) => {
    this.loadpageHandler(event, id);
  };

  onClickDeleteHandler = (event, id) => {
    var response = window.confirm(
      "Are you sure you want to delete this account?\n(May fail if account has created other database objects.)"
    );
    if (response === true) {
      this.loadpageHandler(event, id);
    }
  };

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/register",
            state: {
              message: {
                type: "success",
                content: "admin",
              },
            },
          }}
        />
      );
    }

    let header = (
      <>
        <Row>
          <Col sm={10}>
            <b>Users</b>
          </Col>
          <Col sm={2}>
            <Button
              size="sm"
              variant="danger"
              onClick={(event) => this.setState({ redirect: true })}
            >
              Create
            </Button>
          </Col>
        </Row>
      </>
    );
    let content = <p>Loading ...</p>;

    // render message
    let msg = null;
    if (this.state.internalMsg.content.length > 0) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // explicit display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        const ordered_headers = this.sortByOrder(this.state.table.header);

        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            if (entry.display === "table" || entry.display === "both") {
              return <th key={key}>{entry.name}</th>;
            }
          }
        );

        const table_content = this.state.table.content.map((element, idx) => {
          let modal_content = {};
          let action_item = <p>N/A</p>;

          for (const key in ordered_headers) {
            if (
              ordered_headers[key].display === "modal" ||
              ordered_headers[key].display === "both"
            ) {
              modal_content[key] = {
                value:
                  key === "host_pillar"
                    ? SysMapper[element[key]]
                    : element[key],
                type: ordered_headers[key].style,
                header: ordered_headers[key].name,
              };
            }

            if (ordered_headers[key].type === "action") {
              if (element[key] === "admin-super") {
                action_item = (
                  <>
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={(event) =>
                        this.onClickModifyHandler("modify", element["user_id"])
                      }
                    >
                      Modify
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={(event) =>
                        this.onClickDeleteHandler("delete", element["user_id"])
                      }
                    >
                      Delete
                    </Button>
                  </>
                );
              }
            }
          }

          const modalElement = (
            <ModalElement displayMode="display" message={modal_content} />
          );

          const renderedRow = Object.entries(ordered_headers).map(
            ([key, entry], row_idx) => {
              if (entry.display === "table" || entry.display === "both") {
                if (key === "title") {
                  return <td key={row_idx}>{modalElement}</td>;
                } else if (key === "action") {
                  return <td key={row_idx}>{action_item}</td>;
                } else if (key === "affiliation") {
                  return (
                    <td key={row_idx}>
                      {SysMapper[element[key]]
                        ? SysMapper[element[key]]
                        : element[key]}
                    </td>
                  );
                } else {
                  return <td key={row_idx}>{element[key]}</td>;
                }
              }
            }
          );

          return <tr key={idx}>{renderedRow}</tr>;
        });

        content = (
          <Table striped bordered>
            <thead>
              <tr>{table_header}</tr>
            </thead>
            <tbody>{table_content}</tbody>
          </Table>
        );

        //} else if (this.state.render.content_type === 'form') {
      } else if (this.state.render.content_type === "modify") {
        // Display special modification form based off registration
        // and settings form.

        if (this.state.form.content) {
          header = <b>Modify user ID: {this.state.form.content[0].user_id}</b>;
        } else {
          header = <b>Create a new user</b>;
        }
        content = (
          <UserModificationForm
            user_info={this.state.form}
            parentHandler={this.parentHandler}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>{content}</Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Users;
