import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Input from "../../components/UI/Input/Input";
import Aux from "../../hoc/Auxx/Auxx";

import { checkValidity } from "../../hoc/Util";
import { customStyle } from "../../hoc/CustomStyle";

// inBuilt bootstrap style
const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class ForgetLogin extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Email Address",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label:
            "Please enter the email address you have used during registration to retrieve your login details",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isEmail: true,
          maxLength: 200,
          required: true,
        },
        value: "",
        valid: false,
        touched: false,
      },
    },

    loading: false,

    message: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  toggleLoading(status) {
    this.setState({ ...this.state, loading: status });
  }

  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  messageHandler(msg_type, msg_content) {
    this.setState({
      ...this.state,
      message: {
        triggered: true,
        type: msg_type,
        content: msg_content,
      },
    });
  }

  submitHandler = (event) => {
    event.preventDefault();

    this.toggleLoading(true);

    if (this.state.controls.email.valid) {
      axios
        .post(process.env.REACT_APP_AXIOS_URL + "forgetlogin/user", {
          email: this.state.controls.email.value,
        })
        .then((received) => {
          this.toggleLoading(false);

          if (received.status === 200) {
            if (received.data.status) {
              this.messageHandler("success", received.data.message);
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler("error", "response not 200");
          }
        })
        .catch((error) => {
          this.toggleLoading(false);

          this.messageHandler("error", "unexpected error: " + error);
        });
    } else {
      this.toggleLoading(false);
    }
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        elementDecorators={formElement.config.elementDecorators}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    // [display on same page] message handler
    let msg = null;
    if (this.state.message.triggered) {
      if (this.state.message.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>{this.state.message.content}</p>
        );
      } else if (this.state.message.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>{this.state.message.content}</p>
        );
      }
    }

    return (
      <Aux>
        <NavigationItems login={false} />
        <Container>
          <Row>
            <Col md={controlClasses.frame.col}>
              {msg}
              <Card style={customStyle.topBuffer30}>
                <Card.Header>
                  <b>Retrieve Login</b>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={this.submitHandler}>
                    {form}
                    {this.state.loading ? (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                          size="sm"
                        />
                        Sending...
                      </Button>
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </Form>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <p>
                    Don't have an account? Click <a href={"/register"}>here</a>{" "}
                    to register one.
                  </p>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

export default ForgetLogin;
