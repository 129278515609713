import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import Input from "../../../components/UI/Input/Input";
import axios from "axios";
import { checkValidity } from "../../../hoc/Util";
import { customStyle } from "../../../hoc/CustomStyle";

class UserModificationForm extends Component {
  getPassedValue(key) {
    // Boilerplate to return a default value to modify if need be.
    if (this.props.user_info.content) {
      if (this.props.user_info.content[0]["action"] === "admin-super") {
        return this.props.user_info.content[0][key];
      }
    }
    return "";
  }

  // FIXME Ugly ugly hack -- we basically replicated it from Register.js.
  // Urgh.
  state = {
    controls: {
      firstname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "First Name",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "First Name",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.getPassedValue("firstname"),
        valid: this.getPassedValue("firstname") !== "",
        touched: false,
      },

      lastname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Last Name",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Last Name",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.getPassedValue("lastname"),
        valid: this.getPassedValue("lastname") !== "",
        touched: false,
      },

      affiliation: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Affiliation",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Affiliation",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        value: this.getPassedValue("affiliation"),
        validation: {
          maxLength: 200,
          required: true,
        },
        valid: this.getPassedValue("affiliation") !== "",
        touched: false,
      },

      department: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Department / Faculty",
          isValid: false,
          isInvalid: false,
          required: true,
        },
        elementDecorators: {
          label: "Department / Faculty",
          feedback: "valid",
          feedbackMsg: "looks good!",
        },
        validation: {
          isText: true,
          maxLength: 200,
          required: true,
        },
        value: this.getPassedValue("department"),
        valid: this.getPassedValue("department") !== "",
        touched: false,
      },
    },

    loading: false,
    back: false,
    isAdmin: false,
    message: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    if (this.props.user_info.content) {
      if (this.props.user_info.content[0]["action"] === "admin-super") {
        this.state.isAdmin = true;
      }
    }
  }

  toggleLoading(status) {
    this.setState({ ...this.state, loading: status });
  }

  // We don't allow the changing of email, user_type.  Everything else is
  // free-for all.
  inputChangedHandler = (event, controlName) => {
    let validateOutput = checkValidity(
      event.target.value,
      this.state.controls[controlName].validation
    );

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: validateOutput.isValid,
        elementDecorators: {
          ...this.state.controls[controlName].elementDecorators,
          feedbackMsg: validateOutput.message,
        },
        touched: true,
      },
    };

    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();

    let validated = true;

    if (!this.state.controls.firstname.valid) {
      validated = false;
      //console.log("firstname field is invalid");
    }

    if (!this.state.controls.lastname.valid) {
      validated = false;
      //console.log("lastname field is invalid");
    }

    if (!this.state.controls.affiliation.valid) {
      validated = false;
      //console.log("affiliation field is invalid");
    }

    if (!this.state.controls.department.valid) {
      validated = false;
      //console.log("department field is invalid");
    }

    if (validated) {
      this.toggleLoading(true);

      // This is different end point.
      let accessToken = localStorage.getItem("access_token");
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "user/update",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          user_id: this.getPassedValue("user_id"),
          firstname: this.state.controls.firstname.value,
          lastname: this.state.controls.lastname.value,
          affiliation: this.state.controls.affiliation.value,
          department: this.state.controls.department.value,
        },
      })
        .then((received) => {
          this.toggleLoading(false);
          //console.log(received.data);

          let _success = received.data.status;
          let _message = received.data.message;

          if (_success) {
            this.setState({
              message: {
                triggered: true,
                type: "success",
                content: "Successfully updated user details.",
              },
            });
          } else {
            this.setState({
              message: {
                triggered: true,
                type: "error",
                content: _message,
              },
            });
          }
        })
        .catch((error) => {
          this.toggleLoading(false);
        });
    } else {
      this.toggleLoading(false);
    }
  };

  redirect() {
    this.setState({ back: true });
  }

  render() {
    if (this.state.back)
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "success",
                content: this.state.message.content,
              },
            },
          }}
        />
      );
    // message handling
    let msg = null;
    if (this.state.message.triggered) {
      if (this.state.message.content.length > 0) {
        if (this.state.message.type === "success") {
          msg = (
            <p style={customStyle.successMessage}>
              {this.state.message.content}
            </p>
          );
        } else if (this.state.message.type === "error") {
          msg = (
            <p style={customStyle.errorMessage}>{this.state.message.content}</p>
          );
        }
      }
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });

      if (key === "user_type" && !this.state.isAdmin) {
        formElementsArray.pop();
      }
    }
    let back = null;
    if (this.state.isAdmin) {
      back = (
        <>
          <Button variant="info" onClick={() => this.redirect()}>
            Back
          </Button>
        </>
      );
    }

    const form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        label={formElement.config.label}
        value={formElement.config.value}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        elementDecorators={formElement.config.elementDecorators}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangedHandler(event, formElement.id)}
      />
    ));

    // It's literally email and user_type, so I'm not even going to dignify
    // it with an iteration.
    let profileElement = (
      <>
        <div>
          <Form.Group as={Row}>
            <Form.Label column>User Type:</Form.Label>
            <Col>
              <Form.Control
                disabled
                readOnly
                defaultValue={this.getPassedValue("user_type")}
              />
            </Col>
          </Form.Group>
        </div>
        <div>
          <Form.Group as={Row}>
            <Form.Label column>Email:</Form.Label>
            <Col>
              <Form.Control
                disabled
                readOnly
                defaultValue={this.getPassedValue("email")}
              />
            </Col>
          </Form.Group>
        </div>
      </>
    );

    return (
      <>
        {msg}
        {back}{" "}
        <Form onSubmit={this.submitHandler}>
          {profileElement}
          {form}
          {this.state.loading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                role="status"
                aria-hidden="true"
                size="sm"
              />
              Updating...
            </Button>
          ) : (
            <Button type="submit">Submit</Button>
          )}
        </Form>
      </>
    );
  }
}

export default UserModificationForm;
