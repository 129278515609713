export const customStyle = {
  items: {
    position: "relative",
    // Originally just left -24%.  But the [mis-]alignment drives me crazy.
    // Making both left and right margins the same truly centres the page.
    //marginLeft: "-24%",
    marginLeft: "-33%",
    marginRight: "-33%",
    align: "centre",
  },

  topBuffer30: {
    marginTop: "30px",
  },

  topBuffer20: {
    marginTop: "20px",
    // Leaving the width here makes inner frames break out, so we comment it
    // out.
    //width: "1100px",
  },

  tabSpan: {
    marginTop: "25px",
    textAlign: "justify",
    width: "100%",
  },

  errorMessage: {
    marginTop: "10px",
    color: "red",
    fontWeight: "bold",
  },

  successMessage: {
    marginTop: "10px",
    color: "green",
    fontWeight: "bold",
  },

  center: {
    textAlign: "center",
  },

  subConfirm: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "orange",
  },

  subForeWord: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "blue",
  },

  subHeader: {
    fontSize: "14px",
    fontWeight: "bold",
  },

  subText: {
    fontSize: "12px",
    color: "blue",
    fontStyle: "italic",
  },
  subTextWarn: {
    fontSize: "12px",
    color: "red",
    fontStyle: "italic bold",
  },

  sectionBorderRed: {
    border: "2px solid red",
    padding: "15px",
  },

  bordered: {
    border: "1px solid black",
    paddingTop: "8px",
  },

  sectionBorderBlue: {
    border: "2px solid blue",
    padding: "15px",
    //width: "1050px"
  },

  // FIXME A hack to keep alignment.
  sectionBorderAnnex: {
    border: "2px solid black",
    padding: "15px",
    //width: "1050px"
  },

  sectionBorderNorm: {
    border: "1px solid black",
    borderRadius: "25px",
    padding: "10px",
  },

  sectionSubText: {
    fontSize: "18px",
    fontWeight: "bold",
  },

  tableCustom: {
    tableLayout: "fixed",
    width: "100%",
  },

  wordBreak: {
    width: "20%",
    wordWrap: "break-word",
  },

  wordBreakTwo: {
    width: "40%",
    wordWrap: "break-word",
  },

  scrollButton: {
    position: "fixed",
  },

  disabledLabel: {
    color: "#aaa",
  },

  enabledLabel: {
    color: "#000",
  },

  hideCheckbox: {
    display: "none",
  },

  unhideCheckbox: {},

  fakeAlignDecimal: {
    textAlign: "right",
  },
};
