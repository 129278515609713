import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { customStyle } from "../../../../hoc/CustomStyle";

const MultiCheckboxes = (props) => {
  /*
        props: {
            elementType: 'checkboxes-inline',
            elementConfig: {
                choices : [
                    {value: 'core1', label: 'Cognitive modelling and systems'},
                    {value: 'core2', label: 'Game theory and economic paradigms'},
                    {value: 'core3', label: 'Heuristic search and optimization'},
                    {value: 'core4', label: 'Knowledge representation and reasoning'},
                    {value: 'core5', label: 'Machine learning'},
                    {value: 'core6', label: 'Multiagent systems'},
                    {value: 'core7', label: 'Natural language processing (NLP)'},
                    {value: 'core8', label: 'Planning and scheduling'},
                    {value: 'core9', label: 'Reasoning under uncertainty'},
                    {value: 'core10', label: '​Robotics'},
                    {value: 'core11', label: 'Search and constraint satisfaction'},
                    {value: 'core12', label: 'Vision'}
                ],
                isValid : false,
                isInvalid : false,
            },
            elementDecorators: {
                label: 'Classification of AI Core Technical Areas of the Proposal',
                feedback: 'valid', 
                feedbackMsg: 'looks good!'
            },
            value: '',
            validation: {
                required: true
            },
            valid: true,
            touched: false
        }
    */

  const checkboxes = props.element.elementConfig.choices.map((entry, idx) => {
    let isDisabled = false;
    let isChecked = props.element.value.includes(entry.value);
    if (props.dependent !== null) {
      if (props.dependent === "none") {
        isDisabled = true;
      } else if (entry.value.split("-")[0] !== props.dependent) {
        isChecked = false;
        isDisabled = true;
      }
    }

    return (
      <div
        key={idx}
        style={
          isDisabled ? customStyle.hideCheckbox : customStyle.unhideCheckbox
        }
      >
        <label key={idx}>
          <input
            type="checkbox"
            value={entry.value}
            onClick={(event) => props.onchanged(event)}
            checked={isChecked}
            disabled={isDisabled}
          />{" "}
          <span
            style={
              isDisabled ? customStyle.disabledLabel : customStyle.enabledLabel
            }
          >
            {entry.label}
          </span>
        </label>
      </div>
    );
  });

  let msg = null;
  if (props.element.touched) {
    if (props.element.valid) {
      msg = (
        <p style={customStyle.successMessage}>
          {props.element.elementDecorators.feedbackMsg}
        </p>
      );
    } else {
      msg = (
        <p style={customStyle.errorMessage}>
          {props.element.elementDecorators.feedbackMsg}
        </p>
      );
    }
  }

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={3}>
          <b>{props.element.elementDecorators.label}</b>
          <p style={customStyle.subText}>
            {props.element.elementDecorators.sublabel}
          </p>
        </Form.Label>
        <Col sm={8}>
          {checkboxes}
          {msg}
        </Col>
      </Form.Group>
    </>
  );
};

export default MultiCheckboxes;
