import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import SubmissionForm from "./SubmissionForm/SubmissionForm";
import EndorsePage from "./EndorsePage/EndorsePage";
import SubmissionView from "./SubmissionView/SubmissionView";

import { FormMapper, SysMapper } from "../../../hoc/FormConfig";
import { customStyle } from "../../../hoc/CustomStyle";
import axios from "axios";

class Submissions extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table, form, endorse
      user_type: null,
    },

    table: {
      header: null,
      content: null,
      others: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    view: {
      retrieve_id: null,
      header: null,
      content: null,
      status: null,
    },

    endorse: {
      submission_id: null,
      quota_type: null,
    },

    spinner: {
      toggle: false,
      msg: "",
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.formHandler = this.formHandler.bind(this);
  }

  toggleSpinner(trigger, message) {
    this.setState({
      ...this.state,
      spinner: {
        toggle: trigger,
        msg: message,
      },
    });
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  loadpageHandler(content_type, content_id) {
    this.toggleSpinner(true, "null");

    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      let params = {
        request_type: content_type,
        retrieve_id: content_id,
      };

      if (params) {
        // table
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "proposals/fetch",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: params.request_type,
            retrieve_id: params.retrieve_id,
          },
        })
          .then((received) => {
            this.toggleSpinner(false, "null");

            if (received.status === 200) {
              if (received.data.status) {
                let updatedStates = {};
                if (params.request_type === "table") {
                  updatedStates = {
                    ...this.state,
                    table: {
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      others: received.data.payload.others,
                    },
                    form: {
                      retrieve_id: null,
                      header: null,
                      content: null,
                    },
                  };
                } else if (params.request_type === "form") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                      others: null,
                    },
                  };
                } else if (params.request_type === "view") {
                  updatedStates = {
                    ...this.state,
                    view: {
                      ...this.state.view,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      status: received.data.payload.status,
                    },
                  };
                }
                updatedStates = {
                  ...updatedStates,
                  render: {
                    loaded: true,
                    content_type: params.request_type,
                    user_type: received.data.payload.user_type,
                  },
                };

                this.setState(updatedStates);
              } else {
                this.messageHandler("error", received.data.message);
              }
            } else {
              this.messageHandler(
                "error",
                "Unexpected error, contact site administrator if persist."
              );
            }
          })
          .catch((error) => {
            this.toggleSpinner(false, "null");

            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.messageHandler(
                "error",
                "An Unexpected error has occurred, please contact site admin if persists."
              );
            }
          });
      } else {
        this.toggleSpinner(false, "null");

        this.messageHandler(
          "error",
          "Invalid parameters where parameters are expected"
        );
      }
    } else {
      this.toggleSpinner(false, "null");

      this.messageHandler("expired", "Session expired, please login again");
    }

    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        loaded: false,
      },
    });
  }

  posthandler(post_type, post_id, post_mode) {
    this.toggleSpinner(true, "null");

    let accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "proposals/post",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          post_type: post_type,
          post_data: {
            grant_id: this.props.grant_infor.grant_id,
            submission_id: post_id,
          },
          mode: post_mode,
        },
      })
        .then((received) => {
          this.toggleSpinner(false, "null");

          if (received.status === 200) {
            if (received.data.status) {
              this.loadpageHandler("table", null);
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler(
              "error",
              "Unexpected error occurred, contact site admin if persist"
            );
          }
        })
        .catch((error) => {
          this.toggleSpinner(false, "null");

          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    } else {
      this.toggleSpinner(false, "null");
      this.messageHandler("expired", "Session expired. Please log in again");
    }
  }

  downloadHandler(type, submission_id) {
    const FileDownload = require("js-file-download");
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "download/file",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          submission_id: submission_id,
          download_type: type,
        },
        responseType: "arraybuffer",
      }).then((received) => {
        FileDownload(received.data, received.headers["content-type"]);
      });
    }
  }

  viewHandler(event, submission_id) {
    this.loadpageHandler("view", submission_id);
  }

  modifyHandler(event, submission_id) {
    this.loadpageHandler("form", submission_id);
  }

  deleteHandler(event, submission_id) {
    this.posthandler("delete", submission_id, null);
  }

  createHandler(event) {
    this.posthandler("create", null, null);
  }

  formHandler() {
    this.loadpageHandler("table", null);
  }

  selectHandler(event, submission_id, element) {
    if (event.target.value === "edit") {
      this.loadpageHandler("form", submission_id);
    } else if (event.target.value === "endorse") {
      // XXX Ugly hack to get the quota type from the available content.
      // Linear scan
      let quota_type = element["quota_type"];
      let updateState = {
        ...this.state,
        endorse: { submission_id: submission_id, quota_type: quota_type },
        render: {
          ...this.state.render,
          loaded: true,
          content_type: "endorse",
        },
      };

      this.setState(updateState);
    } else if (event.target.value === "unendorse") {
      this.posthandler("update", submission_id, "unendorse"); // only cluster admin
    } else if (event.target.value === "reject") {
      let response = window.confirm(
        "Are you sure if this application is to be rejected?"
      );
      if (response === true)
        this.posthandler("update", submission_id, "reject"); // only super admin
    } else if (event.target.value === "reject-cluster") {
      let response = window.confirm(
        "Are you sure if this application is to be rejected?"
      );
      if (response === true)
        this.posthandler("update", submission_id, "reject-cluster"); // only cluster admin
    } else if (event.target.value === "proceed") {
      // XXX "proceed" seems to be for moving between stage 1 to stage 2.
      //this.posthandler("update", submission_id, "proceed"); // super admin only
    } else if (event.target.value === "revise-aisg-r") {
      this.posthandler("update", submission_id, "revise-aisg-r"); // super admin only (unimplemented)
    } else if (event.target.value === "revise-cluster") {
      this.posthandler("update", submission_id, "revise-cluster"); // cluster admin only
    } else if (event.target.value === "revert-un") {
      this.posthandler("update", submission_id, "revert-un"); // super admin only
    } else if (event.target.value === "revert-en") {
      this.posthandler("update", submission_id, "revert-en"); // super admin only
    } else if (event.target.value === "awarded") {
      let response = window.confirm(
        "Are you sure if this application is to be awarded?"
      );
      if (response === true) {
        this.posthandler("update", submission_id, "awarded"); // only super admin
      }
    } else if (event.target.value === "conditional_award") {
      let response = window.confirm(
        "Are you sure if this application is to be CONDITIONALLY awarded?"
      );
      if (response === true) {
        this.posthandler("update", submission_id, "conditional_award"); // only super admin
      }
    } else if (event.target.value === "unreject") {
      this.posthandler("update", submission_id, "unreject");
    }
  }

  componentDidMount() {
    this.loadpageHandler("table", null);
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    let header = <b>Submissions</b>;
    let content = <p>Loading ...</p>;

    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // load display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        // cluster admin endorse qouta board
        let quota_board = null;
        if (
          this.props.grant_infor.grant_status === "open" &&
          this.state.render.user_type === "admin-cluster"
        ) {
          let single_endorse = this.state.table.others.current_quota.single;
          let single_max = this.state.table.others.max_quota.single;

          let multiple_endorse = this.state.table.others.current_quota.multiple;
          let multiple_max = this.state.table.others.max_quota.multiple;

          let total_endorse = this.state.table.others.current_quota.total;
          let total_max = this.state.table.others.max_quota.total;

          quota_board = (
            <div>
              <Row>
                <Col sm={2}>
                  <b>Quota:</b>
                </Col>
                <Col sm={8}>
                  <Badge variant="success">
                    Single: {single_endorse}/{single_max}
                  </Badge>{" "}
                  <Badge variant="warning">
                    Multiple: {multiple_endorse}/{multiple_max}
                  </Badge>{" "}
                  <Badge variant="info">
                    Total: {total_endorse}/{total_max}
                  </Badge>
                </Col>
              </Row>
              <hr></hr>
            </div>
          );
        }

        const ordered_headers = this.sortByOrder(this.state.table.header);

        let num_columns = 0;
        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            if (entry.display === "table" || entry.display === "both") {
              num_columns++;
              return <th key={key}>{entry.name}</th>;
            } else {
              return <></>;
            }
          }
        );

        let table_content = (
          <tr>
            <td colSpan={num_columns}>No submission records found ...</td>
          </tr>
        );
        if (this.state.table.content.length > 0) {
          table_content = this.state.table.content.map((element, idx) => {
            let modal_content = {};
            let action_item = <p>N/A</p>;

            for (const key in ordered_headers) {
              // generate modal content
              if (
                ordered_headers[key].display === "modal" ||
                ordered_headers[key].display === "both"
              ) {
                modal_content[key] = {
                  value: element[key],
                  type: ordered_headers[key].style,
                  header: ordered_headers[key].name,
                };

                if (ordered_headers[key].style === "bullet") {
                  let arrayContent = [];
                  let tokenArray = element[key].split(",");

                  for (var i = 0; i < tokenArray.length; i++) {
                    arrayContent.push(FormMapper[tokenArray[i]]);
                  }
                  modal_content[key]["value"] = arrayContent;
                } else if (ordered_headers[key].style === "file") {
                  if (
                    //key === "whitepaper-review" ||
                    key === "submission-review"
                  ) {
                    if (
                      this.state.render.user_type === "submitter" ||
                      this.state.render.user_type === "admin-cluster"
                    ) {
                      // if (this.props.grant_infor.grant_stage === 1) {
                      //   if (
                      //     element["status"] === "proceed" ||
                      //     element["status"] === "reject-aisg"
                      //   ) {
                      //     modal_content[key]["value"] = element[key]
                      //       ? () =>
                      //         this.downloadHandler(
                      //           key,
                      //           element["submission_id"]
                      //         )
                      //       : null;
                      //   } else {
                      //     modal_content[key]["value"] = null;
                      //   }
                      // } else {
                      if (key === "submission-review") {
                        if (
                          element["status"] === "awarded" ||
                          element["status"] === "reject"
                        ) {
                          modal_content[key]["value"] = element[key]
                            ? () =>
                                this.downloadHandler(
                                  key,
                                  element["submission_id"]
                                )
                            : null;
                        } else {
                          modal_content[key]["value"] = null;
                        }
                      } else {
                        modal_content[key]["value"] = element[key]
                          ? () =>
                              this.downloadHandler(
                                key,
                                element["submission_id"]
                              )
                          : null;
                      }
                      //}
                    } else {
                      modal_content[key]["value"] = element[key]
                        ? () =>
                            this.downloadHandler(key, element["submission_id"])
                        : null;
                    }
                  } else {
                    modal_content[key]["value"] = element[key]
                      ? () =>
                          this.downloadHandler(key, element["submission_id"])
                      : null;
                  }
                } else if (key === "host_institution") {
                  modal_content[key]["value"] = SysMapper[element[key]]
                    ? SysMapper[element[key]]
                    : element[key];
                } else if (key === "submission_type") {
                  modal_content[key]["value"] = SysMapper[element[key]]
                    ? SysMapper[element[key]]
                    : element[key];
                }
              }

              // generate action button
              if (ordered_headers[key].type === "action") {
                if (this.state.render.user_type === "admin-super") {
                  if (element["status"] !== "draft") {
                    if (element["status"] === "reject-cluster") {
                      action_item = <>N/A</>;
                    } else {
                      action_item = (
                        <>
                          <Form.Control
                            as="select"
                            value=""
                            onChange={(event) =>
                              this.selectHandler(
                                event,
                                element["submission_id"],
                                element
                              )
                            }
                          >
                            <option value="">(select)</option>
                            {element["status"] === "reject" ? (
                              <>
                                <option value="unreject">Unreject</option>
                              </>
                            ) : (
                              <>
                                <option value="reject">Reject</option>
                              </>
                            )}
                            {element["status"] === "awarded" ? null : (
                              <>
                                <option value="awarded">Award</option>
                              </>
                            )}
                            {element["status"] ===
                            "conditional_award" ? null : (
                              <>
                                <option value="conditional_award">
                                  Conditional Offer
                                </option>
                              </>
                            )}
                          </Form.Control>
                        </>
                      );
                    }
                  }
                } else if (this.state.render.user_type === "submitter") {
                  // grant open
                  if (this.props.grant_infor.grant_status === "open") {
                    if (
                      element["status"] === "draft" ||
                      element["status"] === "complete-un"
                    ) {
                      if (element["status"] === "draft") {
                        let deadline = new Date(
                          this.props.grant_infor.closing_date
                        );
                        if (Date.now() < deadline) {
                          action_item = (
                            <>
                              <Button
                                size="sm"
                                variant="primary"
                                onClick={(event) =>
                                  this.modifyHandler(
                                    event,
                                    element["submission_id"]
                                  )
                                }
                              >
                                Modify
                              </Button>{" "}
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={(event) =>
                                  window.confirm(
                                    "Are you sure you want to delete this submssion? This action cannot be undone!"
                                  ) &&
                                  this.deleteHandler(
                                    event,
                                    element["submission_id"]
                                  )
                                }
                              >
                                Delete
                              </Button>
                              {/* {" "}
                            < Button
                              size="sm"
                              variant="warning"
                              onClick={(event) =>
                                this.templateHandler(
                                  element["submission_id"]
                                )
                              }
                            >
                              Templates
                            </Button > */}
                            </>
                          );
                        } else
                          action_item = (
                            <>
                              Deadline to submit by: {deadline.toLocaleString()}
                            </>
                          );
                      } else {
                        action_item = (
                          <>
                            <Button
                              variant="warning"
                              onClick={(event) =>
                                this.downloadHandler(
                                  "submission",
                                  element["submission_id"],
                                  element["title"]
                                )
                              }
                            >
                              Download unendorsed application
                            </Button>
                          </>
                        );
                      }
                    } else if (element["status"] === "complete-en") {
                      action_item = (
                        <>
                          <Button
                            variant="warning"
                            onClick={(event) =>
                              this.downloadHandler(
                                "submission",
                                element["submission_id"],
                                element["title"]
                              )
                            }
                          >
                            Download full application
                          </Button>
                        </>
                      );
                    } else if (
                      //element["status"] === "complete-en" ||
                      //element["status"] === "revise-aisg-r"
                      element["status"] === "revise-cluster"
                    ) {
                      action_item = (
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={(event) =>
                            this.modifyHandler(event, element["submission_id"])
                          }
                        >
                          Modify
                        </Button>
                      );
                    }
                  }
                } else if (this.state.render.user_type === "admin-cluster") {
                  // cluster admin
                  if (this.props.grant_infor.grant_status === "open") {
                    if (element["status"] !== "draft") {
                      let deadline = new Date(
                        this.props.grant_infor.closing_date
                      );
                      if (Date.now() < deadline) {
                        var options = null;
                        switch (element["status"]) {
                          case "complete-un":
                            options = (
                              <>
                                <option value="">(select)</option>
                                {/* <option value="unendorse">Unendorse</option> */}
                                <option value="endorse">Endorse</option>
                                <option value="edit">Edit</option>
                                <option value="reject">Reject</option>
                                <option value="revise-cluster">
                                  Revision Request
                                </option>
                              </>
                            );
                            break;

                          case "complete-en":
                            options = (
                              <>
                                <option value="">(select)</option>
                                <option value="unendorse">Unendorse</option>
                                <option value="reject">Reject</option>
                              </>
                            );
                            break;
                          case "revise-aisg-r":
                            options = (
                              <>
                                <option value="">(select)</option>
                                <option value="edit">Edit</option>
                                <option value="reject">Reject</option>
                              </>
                            );
                            break;
                          case "revise-aisg-c":
                            options = (
                              <>
                                <option value="">(select)</option>
                                <option value="endorse">Endorse</option>
                                <option value="edit">Edit</option>
                                <option value="reject">Reject</option>
                              </>
                            );
                            break;
                          default:
                            options = (
                              <>
                                <option value="">(select)</option>
                              </>
                            );
                            break;
                        }
                        action_item = (
                          <>
                            <Form.Control
                              as="select"
                              value=""
                              onChange={(event) =>
                                this.selectHandler(
                                  event,
                                  element["submission_id"],
                                  element
                                )
                              }
                            >
                              {options}
                            </Form.Control>
                          </>
                        );
                      } else {
                        switch (element["status"]) {
                          case "complete-en":
                            action_item = <>No action available.</>;
                            break;
                          default:
                            action_item = (
                              <>
                                Deadline to endorse by:{" "}
                                {deadline.toLocaleString()}
                              </>
                            );
                            break;
                        }
                      }
                    }
                  }
                }
              }
            }

            //const modalElement = <ModalElement displayMode='display' message={modal_content} />

            /* Kinda hacky... */
            const modalElement = (
              <span
                className="btn-link"
                onClick={(event) =>
                  this.viewHandler(
                    event,
                    modal_content["submission_id"]["value"]
                  )
                }
              >
                {modal_content["title"]["value"]}
              </span>
            );

            const rowElement = Object.entries(ordered_headers).map(
              ([key, entry], row_idx) => {
                if (entry.display === "table" || entry.display === "both") {
                  if (key === "title") {
                    return (
                      <td key={row_idx} style={customStyle.wordBreak}>
                        {modalElement}
                      </td>
                    );
                  } else if (key === "action") {
                    return <td key={row_idx}>{action_item}</td>;
                  } else if (key === "status" || key === "submission_type") {
                    return <td key={row_idx}>{SysMapper[element[key]]}</td>;
                  } else {
                    return <td key={row_idx}>{element[key]}</td>;
                  }
                } else {
                  return <></>;
                }
              }
            );

            return <tr key={idx}>{rowElement}</tr>;
          });
        }

        if (this.state.render.user_type === "submitter") {
          header = (
            <>
              <Row>
                <Col sm={9}>
                  Create a new submission? Click the button on the right.
                </Col>
                <Col sm={3}>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={(event) => this.createHandler(event)}
                  >
                    Create
                  </Button>{" "}
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={(event) =>
                      this.downloadHandler("templates", "templates")
                    }
                  >
                    Download Templates
                  </Button>
                </Col>
              </Row>
            </>
          );
        } else if (this.state.render.user_type === "admin-super") {
          header = (
            <>
              <Row>
                <Col sm={10}>
                  <b>Submissions</b>
                </Col>
                <Col sm={2}>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={(event) => this.downloadHandler("all", "all")}
                  >
                    Download all
                  </Button>
                </Col>
              </Row>
            </>
          );
        } else if (this.state.render.user_type === "admin-cluster") {
          header = (
            <>
              <Row>
                <Col sm={9}>
                  <b>Submissions</b>
                </Col>
                <Col sm={3}>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={(event) =>
                      this.downloadHandler("endorsement", "endorsement")
                    }
                  >
                    Download endorsement form
                  </Button>
                </Col>
              </Row>
            </>
          );
        }

        content = (
          <>
            {quota_board}
            <Table striped bordered responsive>
              <thead>
                <tr>{table_header}</tr>
              </thead>
              <tbody>{table_content}</tbody>
            </Table>
          </>
        );
      } else if (this.state.render.content_type === "form") {
        header = (
          <b>
            Edit Submission with Id: {this.state.form.content["submission_id"]}
          </b>
        );
        content = (
          <SubmissionForm
            sub_infor={this.state.form}
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
          />
        );
      } else if (this.state.render.content_type === "endorse") {
        header = (
          <b>Endorse Submission with Id: {this.state.endorse.submission_id}</b>
        );
        content = (
          <EndorsePage
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
            submission_id={this.state.endorse.submission_id}
            endorse_type={this.state.endorse.quota_type}
            quota={this.state.table.others}
          />
        );
      } else if (this.state.render.content_type === "view") {
        header = (
          <b>
            Summary [Submission Id: {this.state.view.content["submission_id"]}]
          </b>
        );
        content = (
          <SubmissionView
            formHandler={this.formHandler}
            downloadHandler={this.downloadHandler}
            grant_infor={this.props.grant_infor}
            sub_infor={this.state.view}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>
              {this.state.spinner.toggle ? (
                <>
                  <Spinner animation="border" size="sm" /> Loading, please do
                  not refresh your page{" "}
                </>
              ) : null}
              {content}
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Submissions;
