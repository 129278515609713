import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Settings from "./Settings/Settings";
import Grants from "./Grants/Grants";
import Submission from "./Submissions/Submissions";
import Users from "./Users/Users";
import Reviews from "./Reviews/Reviews";

import { customStyle } from "../../hoc/CustomStyle";
import Aux from "../../hoc/Auxx/Auxx";
import axios from "axios";

const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class Dashboard extends Component {
  state = {
    access: {
      token: null,
    },

    render: {
      default: "submissions",
      type: "table", // table, form-create, form-update
    },

    grant_infor: {
      grant_id: "",
      grant_status: "",
      opening_date: "",
      closing_date: "",
      user_type: "",
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    errors: {
      trigger: false,
    },
  };

  constructor(props) {
    super(props);
    this.callbackDashboard = this.callbackDashboard.bind(this);
  }

  callbackDashboard = (getState, payload) => {
    if (getState.type === "create-sub") {
      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          default: "submissions",
          type: "form-create",
        },
      });
    } else if (getState.type === "table-sub") {
      this.setState({
        ...this.state,
        render: {
          ...this.state.render,
          default: "submissions",
          type: "table",
        },
      });
    }
  };

  setTabKey = (key) => {
    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        default: key,
      },
    });
  };

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  // <----- handlers ----->
  componentDidMount() {
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "grantcalls/fetch",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: "infor",
          retrieve_id: null,
        },
      })
        .then((received) => {
          if (received.status === 200) {
            if (received.data.status) {
              let updatedState = { ...this.state };

              if (
                received.data.payload["user_type"] === "reviewer-local" ||
                received.data.payload["user_type"] === "reviewer-inter"
              ) {
                updatedState.render.default = "reviews";
              }

              updatedState.access = { token: accessToken };
              updatedState.grant_infor = received.data.payload;
              updatedState.externalMsg = {
                triggered: true,
                type: "success",
                content: "",
              };

              this.setState(updatedState);
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    } else {
      this.messageHandler("expired", "Login required!");
    }
  }

  render() {
    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    let tab_element = <p>Loading ...</p>;
    if (this.state.access.token) {
      if (
        this.state.grant_infor.user_type === "submitter" ||
        this.state.grant_infor.user_type === "admin-cluster"
      ) {
        tab_element = (
          <>
            <Tabs
              activeKey={this.state.render.default}
              style={customStyle.tabSpan}
              onSelect={(k) => this.setTabKey(k)}
            >
              <Tab eventKey="submissions" title="Submissions">
                <Submission grant_infor={this.state.grant_infor} />
              </Tab>
              <Tab eventKey="grants" title="Grant Calls">
                <Grants callbackDashboard={this.callbackDashboard} />
              </Tab>
              <Tab eventKey="settings" title="Settings">
                <Settings />
              </Tab>
            </Tabs>
          </>
        );
      } else if (this.state.grant_infor.user_type === "admin-cluster") {
        tab_element = (
          <>
            <Tabs
              activeKey={this.state.render.default}
              style={customStyle.tabSpan}
              onSelect={(k) => this.setTabKey(k)}
            >
              <Tab eventKey="reviews" title="Reviews">
                <Reviews grant_infor={this.state.grant_infor} />
              </Tab>
              <Tab eventKey="grants" title="Grant Calls">
                <Grants callbackDashboard={this.callbackDashboard} />
              </Tab>
              <Tab eventKey="settings" title="Settings">
                <Settings />
              </Tab>
            </Tabs>
          </>
        );
      } else if (
        this.state.grant_infor.user_type === "reviewer-local" ||
        this.state.grant_infor.user_type === "reviewer-inter"
      ) {
        tab_element = (
          <>
            <Tabs
              activeKey={this.state.render.default}
              style={customStyle.tabSpan}
              onSelect={(k) => this.setTabKey(k)}
            >
              <Tab eventKey="reviews" title="Reviews">
                <Reviews grant_infor={this.state.grant_infor} />
              </Tab>
              <Tab eventKey="grants" title="Grant Calls">
                <Grants callbackDashboard={this.callbackDashboard} />
              </Tab>
              <Tab eventKey="settings" title="Settings">
                <Settings />
              </Tab>
            </Tabs>
          </>
        );
      } else if (this.state.grant_infor.user_type === "admin-super") {
        tab_element = (
          <>
            <Tabs
              activeKey={this.state.render.default}
              style={customStyle.tabSpan}
              onSelect={(k) => this.setTabKey(k)}
            >
              <Tab eventKey="submissions" title="Submissions">
                <Submission grant_infor={this.state.grant_infor} />
              </Tab>
              <Tab eventKey="reviews" title="Reviews">
                <Reviews grant_infor={this.state.grant_infor} />
              </Tab>
              <Tab eventKey="users" title="Users">
                <Users />
              </Tab>
              <Tab eventKey="grants" title="Grant Calls">
                <Grants callbackDashboard={this.callbackDashboard} />
              </Tab>
              <Tab eventKey="settings" title="Settings">
                <Settings />
              </Tab>
            </Tabs>
          </>
        );
      }
    }

    return (
      <Aux>
        <NavigationItems login={true} />
        <Container>
          <Row>
            <Col md={controlClasses.frame.col}>
              <div style={customStyle.items}>{tab_element}</div>
            </Col>
          </Row>
          <br></br>
        </Container>
      </Aux>
    );
  }
}

export default withRouter(Dashboard);
